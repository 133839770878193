<template>
    <div class="animated fadeIn">
        <b-card class="piplines-list">
            <b-card-header class="card-header-main">
                <b-row class="piplines-list-header">
                    <b-col md="2">
                        <project-router-link to="/reports/queryjobs/create">
                            <b-button :disabled="isViewer" class="text-nowrap" variant="primary">
                                <i class="icon-plus mr-1"></i>Add new Query Job
                            </b-button>
                        </project-router-link>
                    </b-col>
                </b-row>
            </b-card-header>
            <b-card-body>
                <wit-table
                    mode="server"
                    :search="true"
                    :fields="columns"
                    :request-function="requestFunction"
                    striped
                    paginated
                    hide-per-page-selector
                    ref="queryJobTable"
                    :default-per-page="10"
                >
                    <template v-slot:cell(createdAt)="{item}"> {{ formatDate(item.createdAt) }} </template>
                    <template v-slot:cell(updatedAt)="{item}"> {{ formatDate(item.updatedAt) }} </template>
                    <template v-slot:cell(actions)="{item}">
                        <div class="button-row">
                            <DetailsAction :routerLink="'/reports/queryjobs/' + item.id" />
                            <RemoveAction :removeResource="showRemoveModal" :resource="item" />
                        </div>
                    </template>
                </wit-table>
            </b-card-body>
        </b-card>

        <RemoveRecordModal
            v-model="showModal"
            :removeElement="removeQueryJob"
            :recordId="removeRecordId"
            @close="removeRecordId = null"
        >
            <div slot="error">
                <Feedback :state="queryJobError.state" :invalid="queryJobError.message" />
            </div>
        </RemoveRecordModal>

        <WitModal
            v-model="showWorkflow"
            title="You have created a new Query Job"
            variant="primary"
            customClass="create-workflow-modal"
            size="md"
        >
            <div class="create-workflow-modal__text">
                If you wish to process this resource or put it on a schedule <br />you will need to add it to a workflow
                or create a new one.
            </div>
            <b-row class="d-block text-center">
                <project-router-link to="/workflow"
                    ><b-button id="configure-workflow-button" variant="success" size="md"
                        >Configure a workflow</b-button
                    ></project-router-link
                >
                <span class="pad-buttons" />
                <b-button variant="danger" size="md" @click="() => (showWorkflow = !showWorkflow)">Skip</b-button>
            </b-row>
        </WitModal>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import moment from 'moment'
import VueNotifications from 'vue-notifications'
import {mapGetters} from 'vuex'

import DetailsAction from '@/components/Actions/DetailsAction.vue'
import RemoveAction from '@/components/Actions/RemoveAction.vue'
import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import RemoveRecordModal from '@/components/Modals/RemoveRecordModal.vue'
import WitModal from '@/components/Modals/WitModal.vue'
import WitTable from '@/components/WitTable.vue'

export default {
    data() {
        return {
            searchUrl: `${process.env.VUE_APP_SEARCH_HOST}/search/report`,
            apiUrl: `${process.env.VUE_APP_NODE_API_HOST}/report/queryJob`,
            columns: ['name', 'type', 'subtype', 'createdAt', 'updatedAt', 'actions'],
            showModal: false,
            showWorkflow: false,
            removeRecordId: null,
            queryJobError: {
                state: null,
                message: null,
            },
        }
    },
    components: {
        DetailsAction,
        Loading,
        WitTable,
        RemoveAction,
        RemoveRecordModal,
        Feedback,
        WitModal,
    },
    notifications: {
        queryJobUpdated: {
            type: VueNotifications.types.success,
            title: 'Query Job has been successfully updated.',
        },
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        isViewer() {
            return this.activeProject.role == 'Viewer'
        },
    },
    created() {
        const {queryJobUpdated, queryJobCreated} = this.$route.query

        if (queryJobCreated) {
            this.$router.replace({query: {}})
            this.showWorkflow = true
        }

        if (queryJobUpdated) {
            this.$router.replace({query: {}})
            this.queryJobUpdated()
        }
    },
    mounted() {
        this.reloadTableInterval = setInterval(this.reloadTable, 5000)
    },
    destroyed() {
        clearInterval(this.reloadTableInterval)
    },
    methods: {
        async requestFunction(requestParams) {
            const {query, limit: length, page} = requestParams
            const offset = (page - 1) * length
            const filter = `subtype:'Custom Queries'`
            const projectId = this.$store.state.project.active.id

            const apiUrl = query ? this.searchUrl : this.apiUrl
            const params = query ? {query, length, offset, filter} : {projectId, ...requestParams}

            try {
                const response = await this.axios.get(apiUrl, {params})

                return {
                    data: response.data.data,
                    count: response.data.metadata.count,
                }
            } catch (error) {
                this.dispatch('error', error)
            }
        },

        reloadTable() {
            if (!document.hidden && this.$refs.queryJobTable) this.$refs.queryJobTable.fetchData({silent: true})
        },

        parseLocalDate(_date) {
            const date = new Date(_date)
            return !isNaN(date.getTime()) ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : null
        },
        removeQueryJob() {
            this.queryJobError.state = null
            this.queryJobError.message = null

            this.$store.commit('loading/PROCESSING', `Removing Query Job...`)
            this.axios
                .delete(`${process.env.VUE_APP_NODE_API_HOST}/report/queryJob/${this.removeRecordId}`)
                .then(response => {
                    this.reloadTable()

                    this.showModal = false
                    this.$store.commit('loading/PROCESSED')
                })
                .catch(exception => {
                    if (exception.response.status === 409) {
                        const resourcesToDelete = JSON.parse(exception.response.data.data)
                            .map(({name}) => name)
                            .join(', ')
                        this.queryJobError.message = `We cannot delete this resource. We have detected that it occurs on other resources. First, remove it from these places: ${resourcesToDelete}`
                    } else {
                        this.queryJobError.message = exception.response.data.data
                    }
                    this.queryJobError.state = false
                    this.$store.commit('loading/PROCESSED')
                })
        },
        showRemoveModal(queryJob) {
            this.removeRecordId = queryJob.id
            this.queryJobError.state = null
            this.queryJobError.message = null
            this.showModal = true
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss')
        },
    },
}
</script>

<style <style lang="scss"></style>
